// import './assets/styles/reset.scss';
// import './assets/styles/constants.scss';
import './assets/styles/app.scss';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/500-italic.css';
import '@fontsource/roboto/700-italic.css';
import '@fontsource-variable/inter';

// import './assets/styles/app.scss';
import {
  FontsVTBGroup,
} from '@admiral-ds/react-ui';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// import { ThemeProvider } from 'styled-components';
import { App } from './App';
import { AppCompose } from './components';
import { Home, KeyCloak, Profile } from './module';
import {
  KEYCLOAK_HOME_ROUTE, PROFILE_ROUTE, ROOT_ROUTE, ROUTES,
} from './router';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

console.debug('root', root);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <FontsVTBGroup />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppCompose app={<App />} />}>
            <Route path={ROUTES[ROOT_ROUTE]} element={<Home />} />

            <Route path={ROUTES[KEYCLOAK_HOME_ROUTE]} element={<KeyCloak />} />
            <Route path={ROUTES[PROFILE_ROUTE]} element={<Profile />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
