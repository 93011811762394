import React, { FC } from 'react';

import g_styles from '../../assets/styles/g_styles.module.scss';
import { PropsWithChildren } from '../../types/custom';
import styles from './WLayer.module.scss';
import { WLayerProps } from './WLayer.types';

export const WLayer: FC<PropsWithChildren<WLayerProps>> = ({
  headline, children,
}) => (
  <div className={styles.wrapper}>
    <section className={styles.wHeadline}>
      <div className={styles.headline}>
        <div className={styles.headline_row_center}>
          <h3 className={g_styles.h3}>{headline?.title}</h3>
        </div>
        <div className={styles.headline_row}>
          <span className={g_styles.subTitle_1}>{headline?.subTitle}</span>
        </div>
      </div>
    </section>

    <section className={styles.wBody}>
      {children}
    </section>
  </div>
);
