import {
  BAD_REQUEST_RESPONSE_TYPE_NAME,
  CALL_PHONE_SUCCESS_RESPONSE_TYPE_NAME,
  CANCEL_RESPONSE_TYPE_NAME,
} from '../../common';

export type KeyCloakState = {
  pending: boolean
  data: string
  error: Error | null
};

export enum KeyCloakActionTypes {
  REQUEST_PENDING = 'CATEGORIES_REQUEST_PENDING',
  REQUEST_SUCCESS = 'CATEGORIES_REQUEST_SUCCESS',
  PUSH_ERROR = 'CATEGORIES_PUSH_ERROR',
  CLEAN_ERROR = 'CATEGORIES_CLEAN_ERROR',
}

export interface KeyCloakPendingAction {
  type: typeof KeyCloakActionTypes.REQUEST_PENDING,
}

export interface KeyCloakSuccessAction {
  type: typeof KeyCloakActionTypes.REQUEST_SUCCESS
  payload: string
}

export interface KeyCloakFailedAction {
  type: typeof KeyCloakActionTypes.PUSH_ERROR
  payload: Error
}

export interface KeyCloakCleanErrorAction {
  type: typeof KeyCloakActionTypes.CLEAN_ERROR
}

export type KeyCloakAction = KeyCloakPendingAction | KeyCloakSuccessAction | KeyCloakFailedAction | KeyCloakCleanErrorAction;

// IGNORING_REQUEST_OPTION - отсутствует в RESPONSE_TYPES
export const IGNORING_REQUEST_OPTION = 'IGNORING_REQUEST_OPTION';
export const BAD_REQUEST_OPTION = BAD_REQUEST_RESPONSE_TYPE_NAME;
export const CANCELED_RESPONSE_OPTION = CANCEL_RESPONSE_TYPE_NAME;
export const SUCCESS_RESPONSE_OPTION = CALL_PHONE_SUCCESS_RESPONSE_TYPE_NAME;

export const RESPONSE_OPTIONS = {
  /**
   * При этой опции не проводятся проверки
   */
  [IGNORING_REQUEST_OPTION]: IGNORING_REQUEST_OPTION,

  /**
   *  Тут реальные варианты ответов кейклока
   */
  [BAD_REQUEST_OPTION]: BAD_REQUEST_OPTION,
  [CANCELED_RESPONSE_OPTION]: CANCELED_RESPONSE_OPTION,
  [SUCCESS_RESPONSE_OPTION]: SUCCESS_RESPONSE_OPTION,
};

// export type TT = keyof typeof RESPONSE_OPTIONS[ResponseOptionType];
export type ResponseOptionType = keyof typeof RESPONSE_OPTIONS;

export type Option<L extends string = string, V = string | number | object > = {
  label: L
  value: V,
};

/**
 * Варианты ответа на запрос звонка со стороны веба.
 */
export type ResponseOption = Option<string, ResponseOptionType>;
