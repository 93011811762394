import { Fingerprint, OpenInNew } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { FC, useEffect, useState } from 'react';
import { Panel } from 'src/components/Panel';

// import g_styles from '../../assets/styles/g_styles.module.scss';
import {
  config,
  PostMessenger, postMessenger, ResponseTypeName,
} from '../../common';
import {
  DisplayContainer, HeadlineProps, InputField, WLayer,
} from '../../components';
import styles from './KeyCloak.module.scss';
import {
  BAD_REQUEST_OPTION, CANCELED_RESPONSE_OPTION,
  IGNORING_REQUEST_OPTION,
  ResponseOptionType, SUCCESS_RESPONSE_OPTION,
} from './KeyCloak.types';

export const KeyCloak: FC = () => {
  const [urlToOpen, setUrlToOpen] = useState(config.keycloakLoginHost);
  const [status, setStatus] = useState(false);
  const [isOpenWindow, setIsOpenWindow] = useState(false);
  const [messenger, setMessenger] = useState<PostMessenger | null>(null);
  const [serialNumber, setSerialNumber] = useState(config.serialNumber);
  const [responseOption, setResponseOption] = useState<ResponseOptionType>(SUCCESS_RESPONSE_OPTION);

  useEffect(() => {
    window.document.title = 'IoS :: KeyCloak';
  }, []);
  /*   useEffect(() => {
    setResponseOption(IGNORING_REQUEST_OPTION);
  }, []); */

  useEffect(() => {
    setMessenger(postMessenger);
  }, []);
  const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newUrlToOpen } = e.target;

    setUrlToOpen(newUrlToOpen);
  };
  const handleChangeSerialNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newSerialNumber } = e.target;

    setSerialNumber(newSerialNumber);
  };
  const handleOnOpenWindowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    messenger?.openWindow();
    setIsOpenWindow(true);

    console.debug('[handleOnStartClick] not action..');
  };

  const handleChange = (event: SelectChangeEvent) => {
    const newValue: ResponseOptionType = event.target.value as ResponseOptionType;
    console.debug('change option:', newValue);

    setResponseOption(newValue);
  };

  const callBackConfirmByCall = (): ResponseTypeName | null => {
    if (responseOption) {
      const responseTypeName: ResponseTypeName = responseOption as ResponseTypeName;

      console.debug('[callBackConfirmByCall]: response: ', responseTypeName);

      return responseTypeName;
    }

    return null;
  };
  const handleOnStartClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    messenger?.init(urlToOpen, { serialNumber, cbConfirmByCall: callBackConfirmByCall });
    setStatus(true);
    console.debug('[handleOnStartClick] not action..');
  };

  const headlineConfig: HeadlineProps = {
    title: 'KeyCloak Auth',
    subTitle: 'Тестирование PostMessenger',
  };

  return (
    <WLayer headline={headlineConfig}>
      <div className={styles.flexContainer}>
        <Panel>
          <InputField
            label="Адрес дочернего окна KeyCloak Auth"
            value={urlToOpen}
            onInput={handleChangeUrl}
            wrapIt
          />
          <InputField
            label="Serial number [optional]"
            value={serialNumber ?? ''}
            onInput={handleChangeSerialNumber}
            wrapIt
          />
          <DisplayContainer>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="select-response-type">Ответ на запрос звонка</InputLabel>
                {/* <label className={g_styles.label}>Ответ на запрос звонка</label> */}
                <Select
                  labelId="select-response-type"
                  id="demo-simple-select"
                  value={responseOption as string}
                  label="Ответ на запрос звонка"
                  onChange={handleChange}
                >
                  <MenuItem value={IGNORING_REQUEST_OPTION}>Ignoring Response</MenuItem>
                  <MenuItem value={BAD_REQUEST_OPTION}>Bad Request Response</MenuItem>
                  <MenuItem value={CANCELED_RESPONSE_OPTION}>Canceled Response</MenuItem>
                  <MenuItem value={SUCCESS_RESPONSE_OPTION}>Success Response</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DisplayContainer>

          <div className={styles.status}>
            status:
            {' '}
            { status ? 'on' : 'off'}
          </div>
          <section className={styles.buttonGroup}>
            <Button variant="outlined" color="primary" onClick={handleOnStartClick} disabled={status} endIcon={<Fingerprint />}>

              Включить

            </Button>
          </section>
          <section className={styles.buttonGroup}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleOnOpenWindowClick}
              disabled={isOpenWindow || !status}
              endIcon={<OpenInNew />}
            >
              Открыть дочернее окно
            </Button>
          </section>
        </Panel>
      </div>
    </WLayer>
  );
};
