// eslint-disable-next-line import/no-extraneous-dependencies
import { config, HttpMethod } from 'src/common';

import { KEYCLOAK_LOONA_ID_TOKEN_API_ROUTE_NAME } from '../../api.routes';
import { createSendRequestParamsKeycloak } from '../../api.utils';
import { sendRequest } from '../../sendRequest';
import { JwtTokenRequestData, JwtTokenResponseBody } from './jwtToken.types';

// old мб пригодится
/* export const createPostAccessTokenRequest = async () => {
  const response = await axios({
    method: 'post',
    url: `${config.apiKeycloakHost}/realms/loona-id/protocol/openid-connect/token`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: {
      client_id: 'loona',
      username: config.login,
      password: config.password,
      grant_type: 'password',
      client_secret: config.clientSecret,
      scope: 'openid',
    },
  });

  return response.data.access_token;
}; */

/**
 * Запрос по api данных в Keycloak.
 * Метод POST, по смыслу GET.
 */
export const sendPostJwtTokenRequest = async () => {
  const data = {
    client_id: 'loona',
    username: config.login,
    password: config.password,
    grant_type: 'password',
    client_secret: config.clientSecret,
    scope: 'openid',
  };
  const sendCatalogRequestParams = createSendRequestParamsKeycloak<undefined, JwtTokenRequestData, undefined>(
    KEYCLOAK_LOONA_ID_TOKEN_API_ROUTE_NAME,
    {
      method: HttpMethod.POST,
      data,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    },
  );

  return sendRequest<undefined, JwtTokenRequestData, JwtTokenResponseBody>(sendCatalogRequestParams);
};
