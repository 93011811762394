import classNames from 'classnames';
import React, {
  FC, ReactNode,
} from 'react';

import { Sidebar } from '../Sidebar';
import styles from './AppCompose.module.scss';

export const AppCompose:FC<{ app: ReactNode }> = ({ app }) => (
  <div className={styles.globalApp}>
    <Sidebar />
    <main className={styles.main}>

      <section className={styles.container}>
        <header className={classNames(styles.header)} />
      </section>

      <section className={classNames(styles.container)}>
        {app}
      </section>
    </main>
  </div>
);
