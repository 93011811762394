/**
 * ====================================================
 * ----------------   Request Types    ----------------
 */

export const GET_JWT_TOKEN_HANDLER_NAME = 'getToken';
/**
 * Телефон должен быть в формате: E164, mobile, RU.
 * Иначе натив вернет BAD_REQUEST_RESPONSE.
 */
export const CONFIRM_BY_CALL_HANDLER_NAME = 'confirmByCall';
export const GET_SERIAL_NUMBER_HANDLER_NAME = 'getSerialNumber';
export const LOGOUT_HANDLER_NAME = 'logout';
export const RETURN_TO_APP_FROM_WEB_HANDLER_NAME = 'returnToAppFromWeb';
export const CHANGE_PROFILE_PHOTO_HANDLER_NAME = 'changeProfilePhoto';
export const POST_APP_METRICS = 'postAppmetrica';
export const DELETE_PROFILE_HANDLER_NAME = 'deleteProfile';

/**
 * PostMessage Response code names [as type for {PostMessageResponse}]
 */
/**
 * REQUEST_OK_TYPE_NAME - вариант ответа на запросы требующие подтверждения {need_confirmation}
 * к таким запросам относятся: need_to_call, need_support_number, verificationSucceeded, need_jwt_token.
 *
 * Ок - наиболее востребованный ответ на запросы с подтверждением получения.
 */
export const OK_RESPONSE_TYPE_NAME = 'REQUEST_OK_RESPONSE';
/**
 * REQUEST_CANCELED_TYPE_NAME - вариант ответа на запросы требующие подтверждения {need_confirmation}.
 * В нем также подразумевается тип FAILED/ERROR.
 */
export const CANCEL_RESPONSE_TYPE_NAME = 'REQUEST_CANCELED_RESPONSE';
export const JWT_TOKEN_RESPONSE_TYPE_NAME = 'JWT_TOKEN_RESPONSE';
export const PROFILE_FILLED_RESPONSE_TYPE_NAME = 'PROFILE_FILLED_RESPONSE';
export const CALL_PHONE_SUCCESS_RESPONSE_TYPE_NAME = 'CALL_PHONE_SUCCESS_RESPONSE';
export const SERIAL_NUMBER_RESPONSE_TYPE_NAME = 'SERIAL_NUMBER_RESPONSE';
export const CHANGE_PROFILE_PHOTO_RESPONSE = 'CHANGE_PROFILE_PHOTO_RESPONSE';
/**
 *
 * Вариант немедленного ответа на запрос, который не может быть разобран или данные не соответствуют нужному типу(ам)
 * или значение не соответствует оговоренному формату (например телефон, не E.164, RU).
 *
 * Может не иметь в id равный какому-либо ранее отправленному id.
 *
 * При получении данного типа сообщения, необходимо очистить лист ожидания ответов от id.
 */
export const BAD_REQUEST_RESPONSE_TYPE_NAME = 'BAD_REQUEST_RESPONSE';

export const HANDLERS = {
  [GET_JWT_TOKEN_HANDLER_NAME]: GET_JWT_TOKEN_HANDLER_NAME,

  [CONFIRM_BY_CALL_HANDLER_NAME]: CONFIRM_BY_CALL_HANDLER_NAME,
  [GET_SERIAL_NUMBER_HANDLER_NAME]: GET_SERIAL_NUMBER_HANDLER_NAME,
  [LOGOUT_HANDLER_NAME]: LOGOUT_HANDLER_NAME,
  [DELETE_PROFILE_HANDLER_NAME]: DELETE_PROFILE_HANDLER_NAME,
  [CHANGE_PROFILE_PHOTO_HANDLER_NAME]: CHANGE_PROFILE_PHOTO_HANDLER_NAME,
  [RETURN_TO_APP_FROM_WEB_HANDLER_NAME]: RETURN_TO_APP_FROM_WEB_HANDLER_NAME,
  [POST_APP_METRICS]: POST_APP_METRICS,
};

export type HandlerTypeName = keyof typeof HANDLERS;

export const RESPONSE_TYPES = {
  [OK_RESPONSE_TYPE_NAME]: OK_RESPONSE_TYPE_NAME,
  [CANCEL_RESPONSE_TYPE_NAME]: CANCEL_RESPONSE_TYPE_NAME,

  [JWT_TOKEN_RESPONSE_TYPE_NAME]: JWT_TOKEN_RESPONSE_TYPE_NAME,
  [CALL_PHONE_SUCCESS_RESPONSE_TYPE_NAME]: CALL_PHONE_SUCCESS_RESPONSE_TYPE_NAME,
  [SERIAL_NUMBER_RESPONSE_TYPE_NAME]: SERIAL_NUMBER_RESPONSE_TYPE_NAME,
  [BAD_REQUEST_RESPONSE_TYPE_NAME]: BAD_REQUEST_RESPONSE_TYPE_NAME,
  [CHANGE_PROFILE_PHOTO_RESPONSE]: CHANGE_PROFILE_PHOTO_RESPONSE,
};

export type ResponseTypeName = keyof typeof RESPONSE_TYPES;

/**
 * ========================================================
 * ----------------   Post Message Data   ----------------
 */
export enum JRpcBodyMethod {
  preload = 'preload',
  redirect = 'redirect',
}

/**
 * Дополнительные параметры инициализации.
 * Все параметры должны быть необязательными
 */
export type PostMessengerInitData = {
  jwtToken?: string | null
  serialNumber?: string | null
  cbConfirmByCall?: CallBackConfirmByCall
};

/**
 * Ответ от натива на запрос старта флоу по изменению\удалению\созданию фотографии профиля.
 * - fileId & :false -> к новой фотографии, уже загруженной на сервер;
 * - cancel: true & deleted: false - ничего не делаем; (не грузим и не обновляем);
 * - cancel: false & deleted: true - чистим кеш, ставим дефолтную аватарку (или что по флоу положено).
 */
export type PhotoChangeOperationResult = {
  fileId?: string,
  deleted: boolean,
  cancel: boolean
};

export type JsonRpcError = {
  code?: number;
  message: string;
  data?: any;
};

export type PMResponseResult = string | PhotoChangeOperationResult;

/**
 * От 05.10.2023
 * Все значения должны быть в camelCase нотации
 */
export enum JRpcBodyKey {
  urls = 'urls',
  url = 'url',
  /**
   *  format: E164, mobile, RU.
   *  Иначе натив вернет BAD_REQUEST
   */
  phoneNumber = 'phoneNumber',
  /**
   * Для передачи в Profile deleteProfile
   */
  userId = 'userId',

  /**
   * Metrics params
   */

  /**
   * Required params for Yandex Metrica
   */
  featureName = 'featureName',
  /**
   * Required params for Yandex Metrica
   */
  metricaName = 'metricaName',

  /**
   * Not required params for Yandex Metrica
   */
  firstNestedParam = 'firstNestedParam',
  /**
   * Not required params for Yandex Metrica
   */
  secondNestedParam = 'secondNestedParam',
  /**
   * Not required params for Yandex Metrica
   */
  thirdNestedParam = 'thirdNestedParam',
}

/**
 * JRpcBodyKeyValues - значения enum JRpcBodyKey.
 * Промежуточный (вспомогательный) тип.
 *
 * Initial type: "urls" | "url" | "phoneNumber" | "userId"
 */
export type JRpcBodyKeyValues = keyof typeof JRpcBodyKey;
// export type JRpcBodyKeyValues = keyof Record<JRpcBodyKey, string>;

/**
 * Values from JRpcBodyKey as Key
 *  - дополнен от 05.10.2023
 */
export type PMParams = {
  [key in JRpcBodyKeyValues]?: string;
};

export type PostMessageRequest = {
  /**
   * Генерим для каждого запроса uuid v4.
   * В запросе всегда генерится новый, а ответ должен содержать id-запроса.
   * Реализуем всегда через string.
   */
  id: string,
  jsonrpc: string,

  /**
   * Типизированный набор строк, определяющий адресата (обработчика).
   */
  handler: HandlerTypeName,

  body?: {
    method?: JRpcBodyMethod,
    /**
     * Дополнение от 05.10.2023
     *  - возвращаемся к Object с опциональными параметрами
     */
    params?: PMParams,
  }
};

export type PostMessageResponse = {
  /**
   * Этот id должен быть равен id-запроса { PostMessageRequest }
   */
  id: string,
  jsonrpc: string,
  /**
   * как code для switch-case..
   */
  type: ResponseTypeName,

  /**
   * Это на самом деле value, в оригинале тут может быть result<T>: T
   * string | PhotoChangeOperationResult, not any!
   *
   * string: getToken, getSerialNumber,
   */
  result?: PMResponseResult,

  /**
   * @features сейчас не реализуем.
   */
  error?: JsonRpcError
};

/**
 * Модель данных для отправки в Метрику.
 * Имена (параметры) в будущем будут типизированы во избежание ошибок.
 */
export type AppMetrica = {
  [JRpcBodyKey.featureName]: string
  [JRpcBodyKey.metricaName]: string

  [JRpcBodyKey.firstNestedParam]?: string
  [JRpcBodyKey.secondNestedParam]?: string
  [JRpcBodyKey.thirdNestedParam]?: string
};

/**
 * call-back функция, определяющая поведение (ответ) на запрос confirmByCall.
 */
export type CallBackConfirmByCall = () => ResponseTypeName | null;

export type PostMessenger = {
  // откуда слушаем сообщения
  origin: string | null
  /**
   * url для открытия в дочернем окне.
   * не тоже самое что и origin (+/path/)
   */
  urlToOpen: string | null
  targetWindow: Window | null
  /**
   * Если null, то идет по config.apiKeycloakHost за новым токеном.
   * Если !null, то берет значение
   */
  jwtToken: string | null
  /**
   * Берется из .env файла и может быть заменен через UI
   */
  serialNumber: string | null

  /**
   *  CallBack функция [опция] определяющая ответ на запрос confirmByCall.
   *  При отсутствии параметра - нет ответа;
   *  При наличии: по ее ответу. Если в ответе null - запрос остается без ответа.
   *
   */
  cbConfirmByCall: CallBackConfirmByCall | null

  /**
   * Главная функция инициализации
   * @param urlToOpen - полный адрес открываемого окна, на его базе получаем origin.
   * @param data
   */
  init: (urlToOpen: string, data: PostMessengerInitData) => PostMessenger
  start: VoidFunction
  onListener: VoidFunction

  handleRequest: (data: PostMessageRequest) => void
  handleResponse: (data: PostMessageResponse) => void
  // here for dev options
  sendMessage: (data: PostMessageRequest | PostMessageResponse) => void

  sendOkResponse: (requestId: string) => void
  sendCancelResponse: (requestId: string) => void
  sendTokenResponse: (responseId: string) => void
  sendCallPhoneConfirmResponse: (requestId: string) => void
  sendSupportNumberResponse: (requestId: string) => void
  sendBadRequestResponse: (requestId?: string) => void

  // EXAMPLE FOR ANOTHER SIDE
  sendGetTokenRequest: VoidFunction
  sendConfirmByCallRequest: (phone: string) => void
  sendDeleteProfileRequest: (userId: string) => void
  sendChangeProfilePhoto: VoidFunction
  sendGetSerialNumberRequest: VoidFunction
  sendLogoutRequest: VoidFunction
  sendReturnToAppFromWebRequest: VoidFunction
  sendAppMetrics: (appMetric: AppMetrica) => boolean

  openWindow: VoidFunction
};
