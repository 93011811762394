import React, { FC, PropsWithChildren } from 'react';

import g_styles from '../../assets/styles/g_styles.module.scss';
import styles from './Panel.module.scss';
import { PanelProps } from './Panel.types';

export const Panel: FC<PropsWithChildren<PanelProps>> = ({
  children,
  title,
  subTitle,
}) => {
  const headline = title || subTitle ? (
    <div className={g_styles.headline}>
      {title ? <h3 className={g_styles.h3}>{title}</h3> : null}
      {subTitle ? <span className={g_styles.subTitle_1}>{subTitle}</span> : null}
    </div>
  ) : null;

  return (
    <div className={styles.container}>
      {headline}
      {children}
    </div>
  );
};
