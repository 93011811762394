import classNames from 'classnames';
import React, { FC } from 'react';

import g_styles from '../../assets/styles/g_styles.module.scss';
import { DisplayContainer } from '../DisplayContainer';
import styles from './InputField.module.scss';
import { InputFieldProps } from './InputField.types';

export const InputField: FC<InputFieldProps> = ({
  disabled = false,
  onInput,
  value,
  label,
  wrapIt,
}) => {
  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    if (disabled) {
      e.preventDefault();

      return;
    }

    if (onInput) {
      onInput(e);
    }
  };

  const inputElement = (<input className={classNames(styles.input)} type="text" onInput={handleInput} value={value} disabled={disabled} />);

  const labelElement = !!label && (<label className={g_styles.label}>{label}</label>);
  /**
   * Готовый элемент для встройки или обертки
   */
  const inputField = label ? (
    <div className={styles.field_container}>
      {labelElement}
      {inputElement}
    </div>
  ) : inputElement;

  if (wrapIt) {
    return <DisplayContainer>{inputField}</DisplayContainer>;
  }

  return inputElement;
};
