import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './components/WLayer/WLayer.module.scss';

export const App: FC = () => (
  <section className={styles.wrapper}>
    <Outlet />
  </section>
);
