import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import { rootReducer } from './store.reducer';

/* const logger = createLogger({ diff: true, logErrors: true, collapsed: true });

const middlewares = [thunk, logger];
const middlewareEnhancer = applyMiddleware(...middlewares);

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 50,
  shouldRecordChanges: true,
  shouldHotReload: true,
});

// todo refactoring createStore
const store = createStore(rootReducer, composeEnhancers(middlewareEnhancer)); */
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export { store };
