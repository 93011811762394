import { Reducer } from 'redux';

import { KeyCloakAction, KeyCloakActionTypes, KeyCloakState } from './KeyCloak.types';

export const categoriesInitialState: KeyCloakState = {
  pending: false,
  data: 'Hello',
  error: null,
};

export const keycloakReducer: Reducer<KeyCloakState, KeyCloakAction> = (state = categoriesInitialState, action): KeyCloakState => {
  switch (action.type) {
    case KeyCloakActionTypes.REQUEST_PENDING: {
      return {
        ...state,
        pending: true,
      };
    }
    case KeyCloakActionTypes.REQUEST_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.payload,
      };
    }
    case KeyCloakActionTypes.PUSH_ERROR: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }
    case KeyCloakActionTypes.CLEAN_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
};
