import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import styles from './Button.module.scss';
import { ButtonProps } from './Button.types';

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  disabled = false, appearance, children, onClick,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      e.preventDefault();

      return;
    }

    if (onClick) {
      onClick(e);
    }
  };

  let classList = classNames(styles.btn);

  if (appearance === 'success') {
    classList = classNames(styles.btn, styles.btn_success);
  }
  if (appearance === 'primary') {
    classList = classNames(styles.btn, styles.btn_primary);
  }

  return <button className={classList} type="button" disabled={disabled} onClick={handleClick}>{children}</button>;
};
