export const ROOT_ROUTE = 'ROOT_ROUTE';
export const KEYCLOAK_HOME_ROUTE = 'KEYCLOAK_ROUTE';
export const KEYCLOAK_LOGIN_ROUTE = 'KEYCLOAK_LOGIN_ROUTE';
export const KEYCLOAK_PHONE_CONFIRM_ROUTE = 'KEYCLOAK_PHONE_CONFIRM_ROUTE';
export const PROFILE_ROUTE = 'PROFILE_ROUTE';
export const ROUTES = {
  // [ROOT_ROUTE]: '/ui/pm-mock',
  [ROOT_ROUTE]: '/',

  [PROFILE_ROUTE]: '/profile',
  [KEYCLOAK_HOME_ROUTE]: '/keycloak',
} as const;
