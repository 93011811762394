import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { FC, useEffect, useState } from 'react';

import {
  config,
  PostMessenger,
  postMessenger, ResponseTypeName,
} from '../../common';
import {
  Button, DisplayContainer, HeadlineProps, InputField, Panel, WLayer,
} from '../../components';
import {
  BAD_REQUEST_OPTION, CANCELED_RESPONSE_OPTION,
  IGNORING_REQUEST_OPTION,
  ResponseOptionType,
  SUCCESS_RESPONSE_OPTION,
} from '../KeyCloak/KeyCloak.types';
import styles from './Profile.module.scss';

export const Profile: FC = () => {
  const [urlToOpen, setUrlToOpen] = useState(config.profileAppHost);
  const [status, setStatus] = useState(false);
  const [isOpenWindow, setIsOpenWindow] = useState(false);
  const [messenger, setMessenger] = useState<PostMessenger | null>(null);
  const [jwtToken, setJwtToken] = useState<string | null>(null);
  const [serialNumber, setSerialNumber] = useState(config.serialNumber);
  const [responseOption, setResponseOption] = useState<ResponseOptionType>(SUCCESS_RESPONSE_OPTION);

  useEffect(() => {
    window.document.title = 'IoS :: Profile';
  }, []);

  useEffect(() => {
    setMessenger(postMessenger);
  }, []);

  useEffect(() => {
    messenger?.onListener();
  }, [messenger]);

  const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newUrlToOpen } = e.target;

    setUrlToOpen(newUrlToOpen);
  };
  const handleChangeSerialNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newSerialNumber } = e.target;

    setSerialNumber(newSerialNumber);
  };

  const handleChangeJwtToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newJwtToken } = e.target;

    const newValue = newJwtToken.length > 0 ? newJwtToken : null;
    setJwtToken(newValue);
  };

  const handleOnOpenWindowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    messenger?.openWindow();
    setIsOpenWindow(true);

    console.debug('[handleOnStartClick] not action..');
  };
  const handleChange = (event: SelectChangeEvent) => {
    const newValue: ResponseOptionType = event.target.value as ResponseOptionType;
    console.debug('change option:', newValue);

    setResponseOption(newValue);
  };

  const callBackConfirmByCall = (): ResponseTypeName | null => {
    if (responseOption) {
      const responseTypeName: ResponseTypeName = responseOption as ResponseTypeName;

      console.debug('[callBackConfirmByCall]: response: ', responseTypeName);

      return responseTypeName;
    }

    return null;
  };
  const handleOnStartClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // messenger?.init(urlToOpen, { jwtToken, serialNumber });
    messenger?.init(urlToOpen, { jwtToken, serialNumber, cbConfirmByCall: callBackConfirmByCall });
    setStatus(true);

    console.debug(`[handleOnStartClick] ip: ${urlToOpen} | jwt: ${jwtToken}`);
  };

  const headlineConfig: HeadlineProps = {
    title: 'Browser Profile',
    subTitle: 'Тестирование PostMessenger проекта loona-profile-front',
  };

  return (
    <WLayer headline={headlineConfig}>
      <div className={styles.flexContainer}>
        <Panel>
          <InputField
            label="Адрес дочернего окна Profile"
            value={urlToOpen}
            onInput={handleChangeUrl}
            wrapIt
          />
          <InputField
            label="JWT token to response [optional]"
            value={jwtToken ?? ''}
            onInput={handleChangeJwtToken}
            wrapIt
          />
          <InputField
            label="Serial number [optional]"
            value={serialNumber ?? ''}
            onInput={handleChangeSerialNumber}
            wrapIt
          />
          <DisplayContainer>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="select-response-type">Ответ на запрос звонка</InputLabel>
                {/* <label className={g_styles.label}>Ответ на запрос звонка</label> */}
                <Select
                  labelId="select-response-type"
                  id="demo-simple-select"
                  value={responseOption as string}
                  label="Ответ на запрос звонка"
                  onChange={handleChange}
                >
                  <MenuItem value={IGNORING_REQUEST_OPTION}>Ignoring Response</MenuItem>
                  <MenuItem value={BAD_REQUEST_OPTION}>Bad Request Response</MenuItem>
                  <MenuItem value={CANCELED_RESPONSE_OPTION}>Canceled Response</MenuItem>
                  <MenuItem value={SUCCESS_RESPONSE_OPTION}>Success Response</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DisplayContainer>

          <div className={styles.status}>
            status:
            {' '}
            { status ? 'on' : 'off'}
          </div>
          <section className={styles.buttonGroup}>
            <Button appearance="success" onClick={handleOnStartClick} disabled={status}>
              Включить
            </Button>
          </section>
          <section className={styles.buttonGroup}>
            <Button appearance="success" onClick={handleOnOpenWindowClick} disabled={isOpenWindow || !status}>
              Открыть окно Profile
            </Button>
          </section>

        </Panel>
      </div>
    </WLayer>
  );
};
