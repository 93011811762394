import { PhoneNumberFormat } from 'google-libphonenumber';
import { sendPostJwtTokenRequest } from 'src/api';
import { v4 as uuidv4 } from 'uuid';

import { parsePhoneToFormat } from '../../utils/validation';
import { JSON_RPC_VERSION } from './PostMessenger.constants';
import {
  AppMetrica,
  BAD_REQUEST_RESPONSE_TYPE_NAME,
  CALL_PHONE_SUCCESS_RESPONSE_TYPE_NAME,
  CANCEL_RESPONSE_TYPE_NAME,
  CHANGE_PROFILE_PHOTO_HANDLER_NAME, CHANGE_PROFILE_PHOTO_RESPONSE,
  CONFIRM_BY_CALL_HANDLER_NAME,
  DELETE_PROFILE_HANDLER_NAME,
  GET_JWT_TOKEN_HANDLER_NAME,
  GET_SERIAL_NUMBER_HANDLER_NAME,
  HANDLERS,
  JRpcBodyKey,
  JWT_TOKEN_RESPONSE_TYPE_NAME,
  LOGOUT_HANDLER_NAME,
  OK_RESPONSE_TYPE_NAME, POST_APP_METRICS,
  PostMessageRequest,
  PostMessageResponse,
  PostMessenger,
  PostMessengerInitData,
  RESPONSE_TYPES,
  RETURN_TO_APP_FROM_WEB_HANDLER_NAME,
  SERIAL_NUMBER_RESPONSE_TYPE_NAME,
} from './PostMessenger.types';
import {
  dataIsRequest,
  dataIsRequestOrResponse,
  dataIsResponse,
  extractErrorFromResponse,
  extractParams,
  parseAppMetricaParams,
  parseResultOfChangePhotoOperation,
  parseSerialNumberFromData,
  parseUserIdFromParams,
  responseIsNegative,
} from './PostMessenger.utils';

export const postMessenger: PostMessenger = {

  origin: null,
  urlToOpen: null,
  targetWindow: null,
  jwtToken: null,
  serialNumber: null,
  cbConfirmByCall: null,

  /**
   * Запускает цепочку инициализации модуля, с ориентацией на состояние загрузки DOMContentLoaded.
   * @param urlToOpen - полный адрес открываемого окна, на его базе получаем origin.
   * @param data - {[key: string]: value} типа PostMessengerInitData
   */
  init(urlToOpen: string, data: PostMessengerInitData): PostMessenger {
    this.origin = (new URL(urlToOpen)).origin;
    this.urlToOpen = urlToOpen;
    this.jwtToken = data.jwtToken ?? null;
    this.serialNumber = data.serialNumber ?? null;
    this.cbConfirmByCall = data.cbConfirmByCall ?? null;

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.start());
    } else {
      this.start();
    }

    return this;
  },

  /**
   * Запускает части приложения. Функционирует на уже загруженном DOM.
   */
  start() {
    this.onListener();
  },

  onListener() {
    window.addEventListener('message', (event: Event | any) => {
      if (event.origin === window.location.origin) {
        return;
      }

      let resultParseJson: any | undefined;

      try {
        resultParseJson = JSON.parse(event.data) as any | undefined;
      } catch (e) {
        console.debug('cannot parse data event: ', e);

        return;
      }

      if (!dataIsRequestOrResponse(resultParseJson)) {
        console.debug('[onListener] wrong type of data post-message');

        return;
      }

      const resultAsRequestOrResponse: PostMessageRequest | PostMessageResponse = resultParseJson as PostMessageRequest | PostMessageResponse;

      if (dataIsRequest(resultAsRequestOrResponse)) {
        this.handleRequest(resultAsRequestOrResponse as PostMessageRequest);

        return;
      }

      if (dataIsResponse(resultAsRequestOrResponse)) {
        this.handleResponse(resultAsRequestOrResponse as PostMessageResponse);

        return;
      }

      console.debug('[onListener] wrong type data: ', resultAsRequestOrResponse);
    });
  },

  /**
   * Обработка новых сообщений, с ролью запроса, PostMessageRequest
   * @param data
   */
  handleRequest(data: PostMessageRequest) {
    const params = extractParams(data.body?.params);

    console.debug('[handleRequest] data:', data);
    console.debug(`[handleRequest]: ${data.id} | ${data.handler}], body: `, data.body);

    switch (data.handler) {
      case HANDLERS[GET_JWT_TOKEN_HANDLER_NAME]:
        console.debug('[handleRequest]: GET_JWT_TOKEN_HANDLER_NAME');
        this.sendTokenResponse(data.id);

        break;
      case HANDLERS[CONFIRM_BY_CALL_HANDLER_NAME]:
        console.debug('[handleRequest]: CONFIRM_BY_CALL_HANDLER_NAME');

        /**
         * Позволяет манипулировать ответом в целях тестирования.
         * При наличии прерывает флоу ниже, переводя управление на себя.
         */
        if (this.cbConfirmByCall) {
          switch (this.cbConfirmByCall()) {
            case BAD_REQUEST_RESPONSE_TYPE_NAME:
              console.debug('[handleRequest :: cd]: confirmByCall not found params! Bad Request');
              this.sendBadRequestResponse(data.id);
              break;
            case CANCEL_RESPONSE_TYPE_NAME:
              console.debug('[handleRequest :: cd]: confirmByCall not found params! Bad Request');
              this.sendCancelResponse(data.id);
              break;
            case CALL_PHONE_SUCCESS_RESPONSE_TYPE_NAME:
              console.debug('[handleRequest :: cd]: confirmByCall not found params! Bad Request');
              this.sendCallPhoneConfirmResponse(data.id);
              break;
            default:
              // Ничего не делаем, совершая возврат из функции.

              return;
          }
        }

        if (!params) {
          console.debug('[handleRequest]: confirmByCall not found params! Bad Request');
          this.sendBadRequestResponse(data.id);

          break;
        }
        if (!(JRpcBodyKey.phoneNumber in params)) {
          console.debug(`[handleRequest]: confirmByCall ${JRpcBodyKey.phoneNumber} not found of params! Bad Request`);
          this.sendBadRequestResponse(data.id);

          break;
        }
        if (!parsePhoneToFormat({ number: params[JRpcBodyKey.phoneNumber] ?? '', format: PhoneNumberFormat.E164 })) {
          console.debug(`[handleRequest]: confirmByCall ${JRpcBodyKey.phoneNumber} has bad format! Bad Request`);
          this.sendBadRequestResponse(data.id);

          break;
        }

        this.sendCallPhoneConfirmResponse(data.id);

        break;
      case HANDLERS[GET_SERIAL_NUMBER_HANDLER_NAME]:
        console.debug('[handleRequest]: GET_SERIAL_NUMBER_HANDLER_NAME');
        this.sendSupportNumberResponse(data.id);

        break;
      case HANDLERS[LOGOUT_HANDLER_NAME]:
        console.debug('[handleRequest]: LOGOUT_HANDLER_NAME');

        break;
      case HANDLERS[DELETE_PROFILE_HANDLER_NAME]:
        console.debug('[handleRequest]: DELETE_PROFILE_HANDLER_NAME');

        if (!parseUserIdFromParams(data)) {
          console.debug('[handleRequest]: DELETE_PROFILE_HANDLER_NAME has not params or user_id key');

          break;
        }

        console.debug('[handleRequest]: DELETE_PROFILE_HANDLER_NAME user_id:', parseUserIdFromParams(data));

        break;
      case HANDLERS[CHANGE_PROFILE_PHOTO_HANDLER_NAME]:
        console.debug('[handleRequest]: CHANGE_PROFILE_PHOTO_HANDLER_NAME');

        break;
      case HANDLERS[RETURN_TO_APP_FROM_WEB_HANDLER_NAME]:
        console.debug('[handleRequest]: RETURN_TO_APP_FROM_WEB_HANDLER_NAME');

        break;
      case HANDLERS[POST_APP_METRICS]:
        console.debug('[handleRequest]: POST_APP_METRICS');

        if (!params) {
          console.debug('[handleRequest]: POST_APP_METRICS has not params!');
          this.sendBadRequestResponse(data.id);

          break;
        }
        if (!parseAppMetricaParams(params)) {
          console.debug('[handleRequest]: POST_APP_METRICS has not required params!');
          this.sendBadRequestResponse(data.id);

          break;
        }

        break;
      default:
        console.debug('[handleRequest]: unknown type of request');

        break;
    }
  },

  handleResponse(data: PostMessageResponse) {
    console.debug('[handleResponse] data:', data);

    if (responseIsNegative(data)) {
      console.debug('[handleResponse]: Negative response:', data.id, data.type, data.error);
    } else {
      console.debug(`[handleResponse]: ${data.id} | ${data.type}: `, data.result);
    }

    /**
     * !! Check error key for jsonrpc type of message
     * @feature
     */
    const err = extractErrorFromResponse(data);
    if (err) {
      console.debug('[handleResponse] response has error:', err);

      return;
    }

    switch (data.type) {
      case RESPONSE_TYPES[OK_RESPONSE_TYPE_NAME]:
        console.debug('[handleResponse] GET OK STATUS REQUEST');

        break;
      case RESPONSE_TYPES[CANCEL_RESPONSE_TYPE_NAME]:
        console.debug('[handleResponse] GET CANCEL STATUS REQUEST');

        break;
      case RESPONSE_TYPES[JWT_TOKEN_RESPONSE_TYPE_NAME]:
        console.debug(`[handleResponse] RESPONSE JWT TOKEN: ${data.result}`);

        break;
      case RESPONSE_TYPES[CALL_PHONE_SUCCESS_RESPONSE_TYPE_NAME]:
        console.debug('[handleResponse] CONFIRM PHONE SUCCESS CALLED');

        break;
      case RESPONSE_TYPES[SERIAL_NUMBER_RESPONSE_TYPE_NAME]:
        if (!parseSerialNumberFromData(data.result)) {
          console.debug('[handleResponse] RESPONSE SERIAL NUMBER wrong data!');
          break;
        }
        console.debug(`[handleResponse] RESPONSE SERIAL NUMBER: ${data.result}`);

        break;
      case RESPONSE_TYPES[CHANGE_PROFILE_PHOTO_RESPONSE]:
        if (!parseResultOfChangePhotoOperation(data.result)) {
          console.debug('[handleResponse] CHANGE_PROFILE_PHOTO_RESPONSE wrong data!');
          break;
        }
        console.debug(`[handleResponse] CHANGE_PROFILE_PHOTO_RESPONSE: ${data.result}`);

        break;
      case RESPONSE_TYPES[BAD_REQUEST_RESPONSE_TYPE_NAME]:
        console.debug('[handleResponse] >> BAD REQUEST RESPONSE');

        /**
         *
         * Вариант немедленного ответа на запрос, который не может быть разобран или данные не соответствуют нужному типу(ам)
         * или значение не соответствует оговоренному формату (например телефон, не E.164, RU).
         *
         * Может не иметь в id равный какому-либо ранее отправленному id.
         *
         * При получении данного типа сообщения, необходимо очистить лист ожидания ответов от id.
         */

        break;
      default:
        console.debug(`[handleResponse]: unknown type of response, type: ${data.type}`);
        break;
    }
  },

  /**
   * Отправляет данные указанных типов, самостоятельно проверят targetWindow,
   * преобразовывает данные в JSON.
   * @param data
   */
  sendMessage(data: PostMessageRequest | PostMessageResponse) {
    if (!this.targetWindow) {
      console.debug('Child window are not init! targetWindow:', this.targetWindow);

      return;
    }

    this.targetWindow.postMessage(JSON.stringify(data), '*');
    console.debug('--------------------------------------------------------------');
    console.debug('>> --------------------- Message Send --------------------- <<');
    const typeStr = 'type' in data ? 'RESPONSE' : 'REQUEST';
    const cx = ('type' in data) ? data.type : data.handler;

    console.debug(`[${typeStr}]: ${cx}`);
    console.debug('[data]:', data);
    console.debug('--------------------------------------------------------------');
  },

  /**
   * Открывает `дочернее` окно, для которого будет `window.opener`.
   * С этим окном мы можем обмениваться взаимными сообщения типа postMessage.
   *
   */
  openWindow() {
    if (!this.urlToOpen || (typeof this.urlToOpen === 'string' && this.urlToOpen.length === 0)) {
      console.debug('[openWindow] target window is not exist! cannot open, exit; url:', this.urlToOpen);

      return;
    }
    const win = window.open(new URL(this.urlToOpen));
    if (!win) {
      console.debug(`[openWindow] cannot open window: ${this.urlToOpen}`);

      return;
    }

    this.targetWindow = win;
  },

  sendTokenResponse(responseId: string) {
    const messageData: PostMessageResponse = {
      id: responseId,
      jsonrpc: JSON_RPC_VERSION,
      type: JWT_TOKEN_RESPONSE_TYPE_NAME,
      result: this.jwtToken ?? undefined,
    };

    /**
     *   Если jwt задан через ui, то его и отдаем.
     *   В случае отсутствия - идем на бекенд.
     */
    if (!this.jwtToken) {
      console.debug('[sendTokenResponse]: send request for token');

      sendPostJwtTokenRequest()
        .then((response) => {
          console.debug('[sendTokenResponse] get token from api:', response.data?.access_token);

          const accessToken = response.data?.access_token ?? '';
          if (!accessToken || accessToken.length === 0) {
            console.debug('[sendPostJwtTokenRequest] access token is empty! canceled request');
            this.sendCancelResponse(responseId);

            return;
          }

          messageData.result = accessToken;
          this.sendMessage(messageData);
        })
        .catch((error) => {
          console.debug('[sendTokenResponse]: request canceled, error:', error);
          this.sendCancelResponse(responseId);
        });
    } else {
      console.debug('[sendTokenResponse]: send request for token');

      this.sendMessage(messageData);
    }
  },

  sendOkResponse(responseId: string) {
    const messageData: PostMessageResponse = {
      id: responseId,
      jsonrpc: JSON_RPC_VERSION,
      type: OK_RESPONSE_TYPE_NAME,
    };

    this.sendMessage(messageData);
  },
  sendCancelResponse(responseId: string) {
    const messageData: PostMessageResponse = {
      id: responseId,
      jsonrpc: JSON_RPC_VERSION,
      type: CANCEL_RESPONSE_TYPE_NAME,
    };

    this.sendMessage(messageData);
  },
  sendCallPhoneConfirmResponse(responseId: string) {
    const messageData: PostMessageResponse = {
      id: responseId,
      jsonrpc: JSON_RPC_VERSION,
      type: CALL_PHONE_SUCCESS_RESPONSE_TYPE_NAME,
    };
    console.debug('[sendCallPhoneConfirmResponse]: ', messageData);
    this.sendMessage(messageData);
  },
  sendSupportNumberResponse(responseId: string) {
    if (!this.serialNumber || this.serialNumber.length === 0) {
      console.debug('[sendSupportNumberResponse] serial number is empty! But, sending post message..');
    }

    const messageData: PostMessageResponse = {
      id: responseId,
      jsonrpc: JSON_RPC_VERSION,
      type: SERIAL_NUMBER_RESPONSE_TYPE_NAME,
      result: this.serialNumber ?? '',
    };

    this.sendMessage(messageData);
  },
  sendBadRequestResponse(responseId?: string) {
    const messageData: PostMessageResponse = {
      /**
       * Так как id может быть не получен в битом запросе.
       */
      id: responseId ?? uuidv4(),
      jsonrpc: JSON_RPC_VERSION,
      type: BAD_REQUEST_RESPONSE_TYPE_NAME,
    };

    this.sendMessage(messageData);
  },

  /**
   * Examples request for another side project
   * =========================================
   */
  sendGetTokenRequest() {
    const messageData: PostMessageRequest = {
      id: uuidv4(),
      jsonrpc: JSON_RPC_VERSION,
      handler: GET_JWT_TOKEN_HANDLER_NAME,
    };

    this.sendMessage(messageData);
  },
  sendConfirmByCallRequest(phone: string) {
    const messageData: PostMessageRequest = {
      id: uuidv4(),
      jsonrpc: JSON_RPC_VERSION,
      handler: CONFIRM_BY_CALL_HANDLER_NAME,
      body: {
        params: {
          [JRpcBodyKey.phoneNumber]: phone,
        },
      },
    };

    this.sendMessage(messageData);
  },
  sendGetSerialNumberRequest() {
    const messageData: PostMessageRequest = {
      id: uuidv4(),
      jsonrpc: JSON_RPC_VERSION,
      handler: GET_SERIAL_NUMBER_HANDLER_NAME,
    };

    this.sendMessage(messageData);
  },
  sendReturnToAppFromWebRequest() {
    const messageData: PostMessageRequest = {
      id: uuidv4(),
      jsonrpc: JSON_RPC_VERSION,
      handler: RETURN_TO_APP_FROM_WEB_HANDLER_NAME,
    };

    this.sendMessage(messageData);
  },
  sendDeleteProfileRequest(userId: string) {
    const messageData: PostMessageRequest = {
      id: uuidv4(),
      jsonrpc: JSON_RPC_VERSION,
      handler: DELETE_PROFILE_HANDLER_NAME,
      body: {
        params: {
          [JRpcBodyKey.userId]: userId,
        },
      },
    };

    this.sendMessage(messageData);
  },
  sendChangeProfilePhoto() {
    const messageData: PostMessageRequest = {
      id: uuidv4(),
      jsonrpc: JSON_RPC_VERSION,
      handler: CHANGE_PROFILE_PHOTO_HANDLER_NAME,
    };

    this.sendMessage(messageData);
  },
  sendLogoutRequest() {
    const messageData: PostMessageRequest = {
      id: uuidv4(),
      jsonrpc: JSON_RPC_VERSION,
      handler: LOGOUT_HANDLER_NAME,
    };

    this.sendMessage(messageData);
  },
  sendAppMetrics(appMetric: AppMetrica) {
    const newAppMetric = parseAppMetricaParams(appMetric);
    if (!newAppMetric) {
      console.debug('[sendAppMetrics]: wrong data of AppMetric, reject sending!');

      return false;
    }

    const messageData: PostMessageRequest = {
      id: uuidv4(),
      jsonrpc: JSON_RPC_VERSION,
      handler: POST_APP_METRICS,
      body: {
        params: {
          ...newAppMetric,
        },
      },
    };

    this.sendMessage(messageData);

    return true;
  },
};
