import {
  PhoneNumber, PhoneNumberFormat, PhoneNumberUtil,
} from 'google-libphonenumber';

import { ParsePhoneNumberProps } from './validation.types';

/**
 * Парсит номер в объект PhoneNumber, в зоне RU.
 *
 * Если валидация не пройдет, то вернет null.
 * @param number
 */
export const parsePhoneNumber = (number: string): PhoneNumber | null => {
  const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

  const newNumber = number.replace(/^![+()-0-9]/g, '').trim();
  let parsedNumber: PhoneNumber;

  try {
    parsedNumber = phoneUtil.parse(newNumber, 'RU');
  } catch (err) {
    console.debug('[parsePhoneNumber]: error: ', err);

    return null;
  }

  if (phoneUtil.isValidNumberForRegion(parsedNumber, 'RU')) {
    return parsedNumber;
  }

  return null;
};

/**
 * Приводит телефонный номер из формата PhoneNumber в string согласно стандарту (PhoneNumberFormat).
 * @return string
 */
const formatPhoneNumber = (phoneNumber: PhoneNumber, format: PhoneNumberFormat = PhoneNumberFormat.E164): string => {
  const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

  return phoneUtil.format(phoneNumber, format);
};

/**
 * Основная функция получения номера телефона в нужном формате.
 *
 * @param number
 * @param format
 */
export const parsePhoneToFormat = ({
  number,
  format = PhoneNumberFormat.E164,
}: ParsePhoneNumberProps): string | null => {
  if (!number || (typeof number !== 'string') || (number.length === 0)) {
    return null;
  }

  const phoneNumber = parsePhoneNumber(number);

  if (!phoneNumber) return null;

  return formatPhoneNumber(phoneNumber, format);
};
export const validationPhoneNumber = (number: string | any): boolean => {
  if (!number || (typeof number !== 'string') || (number.length === 0)) {
    return false;
  }

  const phoneNumber = parsePhoneNumber(number);

  return !!phoneNumber;
};
