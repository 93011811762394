import {
  AppMetrica,
  BAD_REQUEST_RESPONSE_TYPE_NAME,
  CANCEL_RESPONSE_TYPE_NAME, JRpcBodyKey,
  JRpcBodyKeyValues,
  JsonRpcError,
  PhotoChangeOperationResult,
  PMParams, PMResponseResult,
  PostMessageRequest,
  PostMessageResponse,
  RESPONSE_TYPES,
} from './PostMessenger.types';

/**
 * Проверяет {data} на возможное соответствие PostMessageRequest | PostMessageResponse.
 * Может служить первичным фильтром, вместо origin.
 *
 * Т.к. на проде нет возможности проверить event.origin на стороне веба, то можно использовать эту функцию.
 *
 * @param data
 * @return false если ошибка компановки объекта или не соответствие типу Object
 */
export const dataIsRequestOrResponse = (data: PostMessageRequest | PostMessageResponse | undefined): boolean => {
// 1. Check undefined
  if (!data) {
    console.debug('[onListener] error: get message, parsed data is undefined: ', data);

    return false;
  }
  // 2. Check is object?
  if (typeof data !== 'object') {
    console.debug('[onListener] error: get message, is not object: ', data);

    return false;
  }

  // 3. check message type, version jsonrpc and required keys
  // eslint-disable-next-line no-restricted-syntax

  if (!('id' in data)) {
    console.debug('[onListener] error: get message, id is not available in data object');

    return false;
  }
  if (!('jsonrpc' in data)) {
    console.debug('[onListener] error: get message, jsonrpc is not available in data object');

    return false;
  }
  // Проверка на тип number убрана, т.к. мы посылаем друг другу uuid v4
  if (typeof data.id !== 'string') {
    console.debug('[onListener] error: get message, id is not string type');

    return false;
  }
  if (String(data.id).length === 0) {
    console.debug('[onListener] error: get message, id length is zero');

    return false;
  }
  if ((data.jsonrpc !== 'string') && (data.jsonrpc !== '2.0')) {
    console.debug('[onListener] error: get message, jsonrpc is not eq v2.0');

    return false;
  }

  // 4. switch request | response
  /**
   * В полученном запросе, всегда должен быть ключ handler
   * В полученном ответе, напротив, может не быть ключа type при наличии ключа error
   */
  if (!('handler' in data) && !('type' in data) && !('error' in data)) {
    console.debug('[onListener] error: get message, cannot get type of data object');

    return false;
  }
  if (('handler' in data) && ('type' in data) && !('error' in data)) {
    console.debug('[onListener] error: get message, cannot get type of data object request | response');
  }

  return true;
};

/**
 * Проверяет данные на соответствие типу {PostMessageRequest}
 * @param data
 */
export const dataIsRequest = (data: PostMessageRequest | PostMessageResponse): boolean => ('handler' in data);

/**
 * Проверяет данные на соответствие типу {PostMessageResponse}
 * @param data
 */
export const dataIsResponse = (data: PostMessageRequest | PostMessageResponse): boolean => ('type' in data) && !('handler' in data);

export const extractErrorFromResponse = (data: PostMessageResponse): JsonRpcError | null => {
  if (typeof data.error === 'object' && 'error' in data && !!data.error && typeof data.error === 'object') {
    console.debug('[handleResponse] response has error:', data.error);

    return data.error;
  }

  return null;
};

/**
 * Проверяет {PostMessageResponse} на содержание доп. поля 'error' или на
 * содержание негативных вариантов ответа: CANCEL_RESPONSE_TYPE_NAME | BAD_REQUEST_RESPONSE_TYPE_NAME
 * @param response
 * @return null - если тип данных
 * b
 */
export const responseIsNegative = (response: PostMessageResponse): boolean | null => {
  if (typeof response !== 'object') {
    return null;
  }

  if ('error' in response) {
    return true;
  }

  //  Перестраховка, т.к. это bad response, он должен раньше на проверках отвалиться
  if (!('type' in response) || typeof response.type !== 'string') {
    return null;
  }

  const resType = response.type;

  return resType === RESPONSE_TYPES[CANCEL_RESPONSE_TYPE_NAME] || resType === RESPONSE_TYPES[BAD_REQUEST_RESPONSE_TYPE_NAME];
};

/**
 * extractParams - извлекает данные, собрая новый объект, только из действительных ключей JRpcBodyKey.
 * Ведется проверка на значение типа string.
 *
 * @param params
 * @return null - если данных нет, нет нужных ключей или тип значения не соответствует PMParams (:string).
 */
export const extractParams = (params?: any): PMParams | null => {
  if (!params || typeof params !== 'object' || Object.keys(params).length === 0) {
    return null;
  }

  /**
   * Задача найти все ключи, соответствующие JRpcBodyKeyValues, со значением типа string.
   * Излишние ключи не считаются ошибкой, но мы их не добавляем в ответ {pmParams}.
   */

  const pmParams: PMParams = {};
  // ключи в переданном объекте и в JRpcBodyKey
  const jrpcBodyKeys = Object.keys(JRpcBodyKey) as JRpcBodyKeyValues[];

  /**
   * Варианты перебора и проверки.
   *
   *  Перебор идет от ключей JRpcBodyKey объекта, т.к. их число заранее известно и оно мало.
   *  Т.к. мы знаем, что у нас на руках объект, то делаем проверку на включение и тип.
   * */
  jrpcBodyKeys.forEach((jrpcBodyKey) => {
    // console.debug(`[extractParams] ${jrpcBodyKey} in:`, params);

    if (jrpcBodyKey in params && typeof params[jrpcBodyKey] === 'string') {
      pmParams[jrpcBodyKey] = params[jrpcBodyKey];
    }
  });

  if (!Object.keys(params).length) return null;

  return params;
};

/**
 * Получает удобную модель данных из данных типа PMParam.
 * В случае несоответствие типу - возвращает null;
 * @param data
 */
export const parseAppMetricaParams = (data: PMParams | null): AppMetrica | null => {
  if (!data || typeof data !== 'object') return null;

  const getKeyFromData = (key: JRpcBodyKeyValues): string | undefined => ((key in data) ? String(data[key]) : undefined);

  if (!(getKeyFromData(JRpcBodyKey.featureName)) || !(getKeyFromData(JRpcBodyKey.metricaName))) {
    return null;
  }

  // todo: убрать as T
  return {
    [JRpcBodyKey.featureName as string]: data[JRpcBodyKey.featureName],
    [JRpcBodyKey.metricaName as string]: data[JRpcBodyKey.metricaName], // ,

    [JRpcBodyKey.firstNestedParam as string]: getKeyFromData(JRpcBodyKey.firstNestedParam),
    [JRpcBodyKey.secondNestedParam as string]: getKeyFromData(JRpcBodyKey.secondNestedParam),
    [JRpcBodyKey.thirdNestedParam as string]: getKeyFromData(JRpcBodyKey.thirdNestedParam),
  } as AppMetrica;
};

export const parseUserIdFromParams = (data?: any): string | null => {
  const params = extractParams(data);
  if (!params) return null;

  if (!(JRpcBodyKey.userId in params) || typeof params[JRpcBodyKey.userId] !== 'string') return null;

  return String(params[JRpcBodyKey.userId]);
};

/**
 * Получает серийный номер из данных.
 * Делает проверку на соответствие типу и наличию хотя бы 1 символа.
 * @param data
 * @return null - если данных нет или нарушена целостность
 */
export const parseSerialNumberFromData = (data?: PMResponseResult): string | null => {
  if (!data || typeof data !== 'string' || data.length === 0) return null;

  return String(data);
};

/**
 * Парсит ответ CHANGE_PROFILE_PHOTO_RESPONSE на запрос изменения фотографии
 * @param data
 */
export const parseResultOfChangePhotoOperation = (data?: PMResponseResult | null): PhotoChangeOperationResult | null => {
  if (!data || typeof data !== 'object') return null;

  if (!('deleted' in data) || !('cancel' in data)) return null;

  if (typeof data.deleted !== 'boolean' || typeof data.cancel !== 'boolean') return null;

  const changePhotoResult: PhotoChangeOperationResult = {
    fileId: undefined,
    cancel: <boolean>data.cancel,
    deleted: <boolean>data.deleted,
  };

  const notCancelNotDeleted = !changePhotoResult.deleted && !changePhotoResult.cancel;

  if (notCancelNotDeleted && !('fileId' in data)) {
    return null;
  }

  if (notCancelNotDeleted && (('fileId' in data) && typeof data.fileId !== 'string')) {
    return null;
  }

  if (notCancelNotDeleted && (('fileId' in data) && typeof data.fileId === 'string')) {
    const fileId = String(data.fileId);

    if (fileId.length === 0) {
      return null;
    }

    changePhotoResult.fileId = fileId;
  }

  return changePhotoResult;
};
