import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { RequestSkeleton } from './sendRequest.types';

/**
 * Отправляет запросы к хосту.
 * В случае успеха возвращает объект data заданного с пом. дженерика "TData" типа.
 * В случае ошибки выбрасывает либо массив ошибок с бэкенда, либо экземпляр Error
 */
export const sendRequest = async <RequestQueryParams = undefined, RequestBody = undefined, Response = undefined>(
  requestSkeleton: RequestSkeleton<RequestQueryParams, RequestBody>, timeout?: number,
): Promise<Response> => {
  try {
    const requestParams: AxiosRequestConfig = {
      ...requestSkeleton,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json',
        'X-Call-ID': uuidv4(),
        ...requestSkeleton.headers,
      },
    };

    if (timeout) {
      requestParams.timeout = timeout;
    }

    const instance = axios.create();
    const response: AxiosResponse<Response> = await instance(requestParams);

    return response.data;
  } catch (error) {
    console.error(error);

    throw error;
  }
};
