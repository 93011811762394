import { config, HttpMethod } from '../common';
import { RouteName, ROUTES } from '../router';
import { API_ROUTES } from './api.routes';
import {
  ApiRouteName,
  RequestPayload,
} from './api.types';
import { RequestSkeleton } from './sendRequest';

const replaceRouteParams = <RequestRouteParams>(path: string, routeParams: RequestRouteParams): string => {
  let newPath = path;

  if (!routeParams) {
    return newPath;
  }

  const objEntries = Object.entries(routeParams);

  /**
   * Перебираем все ключи и ищем вхождение по :key
   * Подставляем в path :key => value
   * ..повторяем цикл
   */
  objEntries.forEach(([key, value]) => {
    const searchValue = `:${key}`;
    if (newPath.indexOf(searchValue) !== -1) {
      newPath = newPath.replace(searchValue, String(value));
    }
  });

  return newPath;
};

/**
 * Подставляет в RouteName вида /url/:id/category/:categoryId данные из объекта по принципу key: value.
 * Указанная выше строка примет объект типа:
 * CategoryRouteParams {
 *   id: 123,
 *   categoryId: 'uuid-34-1-2343-3'
 * }
 * и вернет /url/123/category/uuid-34-1-2343-3
 *
 * Если паметров не хватит, то встроке останутся необработанные части пути вида ../:key_ident/..
 *
 * */
export const getPathWithRouteParams = <RequestRouteParams>(apiRouteName: ApiRouteName, routeParams: RequestRouteParams): string => {
  const path = API_ROUTES[apiRouteName];

  return replaceRouteParams(path, routeParams);
};

// TODO перенести в routes
export const getAppPathWithRouteParams = <RequestRouteParams>(routeName: RouteName, routeParams: RequestRouteParams): string => {
  const path = ROUTES[routeName];

  return replaceRouteParams(path, routeParams);
};

/**
 * Эта функция:
 * - нормализует данные для sendRequest
 * - добавляет дополнительный путь к урлу
 * - добавляет специфический для проекта baseUrl
 */
export const createSendRequestParams = <
  RequestQueryParams = undefined,
  RequestBody = undefined,
  RequestRouteParams = undefined,
>(
    apiRouteName: ApiRouteName,
    payload?: RequestPayload<RequestQueryParams, RequestBody, RequestRouteParams>,
  ): RequestSkeleton<RequestQueryParams, RequestBody> => {
  const {
    method = HttpMethod.GET, data, queryParams, headers,
  } = payload ?? {};

  const url = getPathWithRouteParams(apiRouteName, payload?.routeParams);

  const requestSkeleton: RequestSkeleton<RequestQueryParams, RequestBody> = {
    baseURL: `${config.apiHost}`,
    params: queryParams,
    url,
    method,
    data,
    headers,
  };

  return requestSkeleton;
};

export const createSendRequestParamsKeycloak = <
  RequestQueryParams = undefined,
  RequestBody = undefined,
  RequestRouteParams = undefined,
>(
    apiRouteName: ApiRouteName,
    payload?: RequestPayload<RequestQueryParams, RequestBody, RequestRouteParams>,
  ): RequestSkeleton<RequestQueryParams, RequestBody> => {
  const {
    method = HttpMethod.GET, data, queryParams, headers,
  } = payload ?? {};

  const url = getPathWithRouteParams(apiRouteName, payload?.routeParams);

  const requestSkeleton: RequestSkeleton<RequestQueryParams, RequestBody> = {
    baseURL: `${config.apiKeycloakHost}`,
    params: queryParams,
    url,
    method,
    data,
    headers,
  };

  return requestSkeleton;
};
