import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  HeadlineProps, WLayer,
} from '../../components';
import { KEYCLOAK_HOME_ROUTE, PROFILE_ROUTE, ROUTES } from '../../router';
import styles from './Home.module.scss';

export const Home: FC = () => {
  useEffect(() => {
    window.document.title = 'IoS Emulate';
  }, []);

  const headlineConfig: HeadlineProps = {
    title: 'IoS Emulate',
    subTitle: 'Смотри README.md. Тестирование PostMessage. ',
  };

  return (
    <WLayer headline={headlineConfig}>
      <div className={styles.home}>
        <Link to={ROUTES[KEYCLOAK_HOME_ROUTE]}>KeyCloak</Link>
        <Link to={ROUTES[PROFILE_ROUTE]}>Profile</Link>
      </div>
    </WLayer>
  );
};
