import classNames from 'classnames';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './NavLink.module.scss';
import { NavbarItemProps } from './NavLink.types';

export const NavLink: FC<NavbarItemProps> = ({
  to, text, icon,
}) => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (to && to !== '/') setIsActive(location.pathname.includes(to));
  }, [location, to]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (to) navigate(to);
  }, [navigate, to]);

  const className = classNames(
    styles.navLink,
    isActive && styles.active,
  );

  return (
    <a
      href={to}
      className={className}
      onClick={handleClick}
    >
      <div className={styles.icon}>
        {icon}
      </div>
      {text}
    </a>
  );
};
