import classNames from 'classnames';
import React from 'react';

import { ReactComponent as HomeIcon } from '../../assets/icons/Home.svg';
import { ReactComponent as Resources } from '../../assets/icons/Resources.svg';
import { ReactComponent as Stories } from '../../assets/icons/Tags.svg';
import {
  KEYCLOAK_HOME_ROUTE, PROFILE_ROUTE,
  ROOT_ROUTE, ROUTES,
} from '../../router';
import { NavLink } from './components';
import styles from './Sidebar.module.scss';

export const Sidebar = () => (
  <aside className={styles.aside}>
    <div className={styles.inner}>
      <nav className={styles.nav} role="navigation">
        <header className={classNames(styles.menuitem, styles.header)}>
          <NavLink to={ROUTES[ROOT_ROUTE]} text="Главная" icon={<HomeIcon />} />
        </header>

        <hr />

        <section className={classNames(styles.menuitem, styles.menuWrapper)}>
          <NavLink to={ROUTES[PROFILE_ROUTE]} text="Profile" icon={<Resources />} />
          <NavLink to={ROUTES[KEYCLOAK_HOME_ROUTE]} text="KeyCloak" icon={<Stories />} />
        </section>
      </nav>
    </div>
  </aside>
);
