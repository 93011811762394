// import process from 'process';

import packageJSON from '../../../package.json';
import { KEYCLOAK_LOGIN_HOST_DEFAULT, PROFILE_APP_HOST_DEFAULT, SERIAL_NUMBER } from '../constants';

export interface IConfig {
  name: string;
  version: string;
  environment: string;
  apiHost: string;
  apiKeycloakHost: string;
  applicationHost: string;
  login: string;
  password: string;
  clientSecret: string;
  serialNumber: string;
  /**
   * Адрес страницы профайла.
   * По дефолту: http://localhost:3000
   * [.env]: REACT_APP_PROFILE_APP_HOST=http://127.0.0.1:3000
   */
  profileAppHost: string;
  /**
   * Адрес страницы KeyCloak'а.
   * По дефолту: http://localhost:8080
   * [.env]: REACT_APP_KEYCLOAK_LOGIN_HOST=http://127.0.0.1:8080
   */
  keycloakLoginHost: string;
}

export const config: IConfig = {
  name: packageJSON.name,
  environment: process.env.NODE_ENV,
  apiHost: process.env.NODE_ENV === 'production' ? '%%API_HOST%%' : (process.env.REACT_APP_API_HOST ?? ''),
  // apiKeycloakHost: process.env.NODE_ENV === 'production' ? '%%KEYCLOAK_APP_HOST%%' : (process.env.REACT_APP_API_KEYCLOAK_HOST ?? 'https://ift-keycloak.loonabrowser.ru/auth'),
  apiKeycloakHost: 'https://ift-keycloak.loonabrowser.ru/auth',
  applicationHost: process.env.NODE_ENV === 'production' ? '%%APPLICATION_HOST%%' : (process.env.REACT_APP_APPLICATION_HOST ?? ''),
  login: process.env.REACT_APP_AUTHORIZED_USER_LOGIN_KEYCLOAK ?? '%%REACT_APP_AUTHORIZED_USER_LOGIN_KEYCLOAK%%',
  password: process.env.REACT_APP_AUTHORIZATION_USER_PASSWORD_KEYCLOAK ?? '%%REACT_APP_AUTHORIZATION_USER_PASSWORD_KEYCLOAK%%',
  clientSecret: process.env.REACT_APP_AUTHORIZATION_CLIENT_SECRET_KEYCLOAK ?? '%%REACT_APP_AUTHORIZATION_CLIENT_SECRET_KEYCLOAK%%',
  profileAppHost: process.env.REACT_APP_PROFILE_APP_HOST ?? PROFILE_APP_HOST_DEFAULT,
  keycloakLoginHost: process.env.REACT_APP_KEYCLOAK_LOGIN_HOST ?? KEYCLOAK_LOGIN_HOST_DEFAULT,
  version: packageJSON.version,
  serialNumber: process.env.REACT_APP_SERIAL_NUMBER ?? SERIAL_NUMBER,
};
