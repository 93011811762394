/** HTTP Request Methods
 * [RFCs concepts] https://webconcepts.info/concepts/http-method/
 *
 * Всего порядка 40 используемых заголовков, но мы ограничиваемся 12
 * (точнее 8 c https://www.rfc-editor.org/rfc/rfc7231#section-4.1 + дополнительные).
 * */
export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',

  /** Метод HEAD идентичен GET, за исключением того, что сервер НЕ ДОЛЖЕН
   возвращать тело сообщения в ответе. Метаинформация, содержащаяся
   в заголовках HTTP в ответ на запрос HEAD, ДОЛЖНА быть идентична
   информации, отправленной в ответ на запрос GET. Этот метод можно
   использовать для получения метаинформации об объекте, подразумеваемом
   запросом, без передачи самого тела объекта. Этот метод
   часто используется для проверки гипертекстовых ссылок на достоверность, доступность
   и чтобы узнать, не изменился ли он с момента последнего обращения. */
  HEAD = 'HEAD',

  /** Используется для описания параметров соединения с целевым ресурсом. Если Request-URI не является звездочкой, запрос OPTIONS применяется
   только к тем параметрам, которые доступны при обмене данными с этим
   ресурсом. */
  OPTIONS = 'OPTIONS',
}

/** HTTP Response status codes
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
 * */
export enum HttpStatusCode {
  /** NOT_SPECIFIED it is understood as 'Network Error'. Not implementation with ANY_SPECIFIED (has ant code) */
  X_NOT_SPECIFIED = 10,

  /** ANY_SPECIFIED - has some code [10x-5xx], but not (!) implementation with NOT_SPECIFIED */
  X_ANY_SPECIFIED = 11,

  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,

  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  MULTI_STATUS = 207,

  MULTIPLE_CHOICES = 300,
  MOVED_PERMANENTLY = 301,
  MOVED_TEMPORARILY = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  USE_PROXY = 305,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,

  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  REQUEST_TOO_LONG = 413,
  REQUEST_URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  IM_A_TEAPOT = 418,
  INSUFFICIENT_SPACE_ON_RESOURCE = 419,
  METHOD_FAILURE = 420,
  MISDIRECTED_REQUEST = 421,
  UNPROCESSABLE_ENTITY = 422,
  LOCKED = 423,
  FAILED_DEPENDENCY = 424,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
  INSUFFICIENT_STORAGE = 507,
  NETWORK_AUTHENTICATION_REQUIRED = 511,
}

export enum HttpStatusCodeRegExp {
  INFORMATIONAL = '10[0-2]',
  SUCCESS = '2[0-2][0-6]',
  REDIRECTION = '30[0-8]',
  CLIENT_ERROR = '4[0-9][0-9]',
  SERVER_ERROR = '5[0-9][0-9]',
}

export enum CustomHttpHeader {
  ExpectedError = 'X-Expected-Error',
  Authorization = 'Authorization',
  RefreshToken = 'Refresh-Token',
  XCallId = 'X-Call-ID',
}
